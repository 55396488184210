//en.js
const hostname = window.location.hostname;
console.log("hostname",hostname);
const en = {
    "baseurl": hostname == "visitor.sialchina.com" ? "" : "/en",
    "lang": "en",
    "head_title": "sial shanghai|sial shenzhen-Visitor Member Center-SIAL China",
    "head_description": "SIAL Shanghai will be held at theShanghai New International Expo Center from May 19-21, 2025.The exhibition area will span a vast expanse of 200,000 square meters, and it is anticipated that 5,000 exhibitors from over 70 countires and regions, and more than 180,000 professionals from 110+countries will together join the show.SIAL Shenzhen will be held at the Shenzhen Convention & Exhibition Center from September 1-3, 2025. The exhibition area will cover 60,000 square meters, expecting to attract over 1,500 exhibitors and over 67,000 professionals from around the world.",
    "head_keywords": "sial shanghai,sial shenzhen,SIAL China",
    "meta": {
        "概览": "Home",
        "首页": "Home",
        "会员信息": "Basic Information",
        "个人信息": "Personal Information",
        "修改密码": "Change Password",
        "参观信息": "Visit information",
        "参观确认函": "Confirmation Letter",
        "问卷信息": "Questionnaire",
        "信息订阅": "Mail subscription",
        "商贸配对": "Matchmaking",
        "配对信息": "Matching Information",
        "在线预约": "Matchmaking",
        "预约管理": "Booking Management",
        "我的收藏": "My Collection",
        "收藏展商": "Collection exhibitor",
        "收藏展品": "Collect exhibits",
        "邀请管理": "Invite Friends",
        "推荐给好友": "Invite Friends",
        "协助他人登记": "Register for Friend",
        "团体导入": "Group import",
        "峰会直播": "Live Streaming",
        "我的订单": "My Order",
        "我的门票": "My Tickets",
        "我的赠票": "Gift Tickets",
        "我的订阅": "My Subscription",
        "线下峰会": "Summit Tickets",
        "峰会购票": "Summit Tickets",
        "白皮书下载": "White Paper Download",
        "我的下载": "My Download",
        "沙龙报名": "沙龙报名",
        "发票申请": "发票申请",
        "展馆交通": "Travel & Accomodation",
        "联系主办方": "Contact",
        "发起的约见": "Appointment sent",
        "收到的约见": "Appointment Received",
        "我的日程": "My Schedule",
    },
    "￥": "$",
    "home": "Home",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "please select payment": "Please select a payment method",
    "select payment": "Select payment method",
    "paid success": "Paid success",
    "paid successfully": "Paid successfully",
    "none payment": "Unpaid",
    "paid": "Paid",
    "Wechat Pay": "Wechat Pay",
    "Alipay Pay": "Alipay Pay",
    "Offline payment": "Bank Transfer",
    "coupon": "Coupon",
    "input coupon": "Please enter your promotion code",
    "submit payment": "Immediate payment",
    "open Alipay payment interface": "Please pay in the open Alipay payment interface",
    "Bank Transfer Information": "Bank Transfer Information",
    "paying": "Paying...",
    "Pay with a swipe on wechat": "Pay with a swipe on wechat",
    "Tickets purchased successfully, please pay offline": "Tickets purchased successfully, please pay offline",
    "You have paid successfully, to My Ticket": 'You have paid successfully, please check the admission code on "My Ticket"!',
    "Warm reminder": "Warm reminder",
    "payment within 2 hours": "Please complete the payment within 2 hours, the order will be automatically cancelled.",
    "Order information": "Order information",
    "Order number": "Order number",
    "truename": "Truename",
    "company": "Company",
    "mobile": "Mobile",
    "email": "Email",
    "number": "Number",
    "unit price": "Unit price",
    "total order price": "Total order price",
    "Discount amount": "Discount amount",
    "Amount actually paid": "Amount actually paid",
    "View order": "View order",
    "live time": "live time",
    "Please log in": "Please log in",
    "Log back in": "Log in",
    "The request timed out. Please refresh and try again": "The request timed out. Please refresh and try again",
    'date': {
        'D': 'Days',
        'h': 'hours',
        'm': 'Minutes',
        's': 'Seconds',
    },
    "livepay tips": "The video is valid for 21 days after purchase",
    "please enter": "Please enter ",
    "please select": "Please select ",
    "save success": "Save successfully",
    "email code": "Email verification code",
    "sms code": "SMS Code",
    "get code": "Get Code",
    "send sms code": "Get Code",
    "send email code": "Get Code",
    "getting code": "Getting...",
    "a valid": "a valid ",
    "to reacquire": "to reacquire",
    "go another language version to pay": "Go to Chinese version to pay",
    "btn": {
        "save": "Save",
        "confirm": "Confirm",
        "submit": "Submit",
        "delete": "Delete",
        "pay now": "Pay Now",
        "cancel": "Cancel",
    },
    "newelive": {
        "tdk": {
            "title": "China Food Exhibition Asia Food Exhibition sial shanghai-Events & Competitions-SIAL China",
            "description": "SIAL China is one of the top three food & beverage exhibitions worldwide. A series of activities and events will be held during the event. On this page, you can see all the events held. If you are interested, you can click to learn more.",
            "keywords": "China Food Exhibition,Asia Food Exhibition,sial shanghai,SIAL China"
        },
        "index": {
            "title": "SIAL Global Food Industry Summit",
            "introduction": "SIAL Global Food Industry Summit focuses on hot topics and categories such as new retail, supply chain, meat, drink, food-tech, import & export food, healthy food, catering, etc., the summit will invite worldwide experts, industry leaders, and professionals to share the latest F&B industry information, analyze the development trends, and help F&B enterprises gain more high-value business opportunities.",
        },
        "back list": "Back",
        "back page": "Back",
        "首页": "Home",
        "登录": "Login",
        "食品产业峰会": "Events & Competitions",
        "选择票种": "Ticket Type",
        "我的门票": "My Tickets",
        "线下峰会": "Summit Tickets",
        "我的订单": "My Order",
        "发票信息": "Invoice",
        "个人信息": "Personal Information",
        "门票核销": "Ticket verification",
        "支付": "Pay",
        "购票成功": "",
        "退出登录": "Logout",
        "登录/注册": "Login",
        "我的": "My",
        "please login": "Please login",
        "实名制预登记": "Register",
        "我要购票": "Purchase Tickets",
        "峰会大咖说": "Highlight Speech",
        "往届回顾": "Summit Review",
        "直播回看": "Live Streaming",
        "个人中心": "Personal Center",
        "home page": "Official Website",
        "上海食品产业峰会": "2025 SIAL Global Food Industry Summit",
        "查看详情": "Details",
        "详情": "More",
        "暂无数据": "Coming soon~",
        "搜索": "Search",
        "请输入关键字": "Please enter a keyword",
        "footer copy": "Comexposium-SIAL Exhibition Co., Ltd. All rights Reserved",
        "step 订单确认": "Order Confirmation",
        "step 支付方式": "Payment Method",
        "step 购票完成": "Ticket Purchase Completed",
        "票种": "Ticket type",
        "数量": "Quantity",
        "早鸟价": "Early bird price: ",
        "正价": "Regular Price: ",
        "select 票种": "Please select ticket type",
        "订单信息": "Order information",
        "会议名称": "Meeting name",
        "票价": "Fare",
        "兑换码": "Exchange code: ",
        "请输入兑换码": "Please enter Exchange code",
        "兑换码重复": "The redemption code is duplicated",
        "确认使用": "Confirm",
        "新增兑换码": "Add Exchange code",
        "订单金额合计": "Total order amount: ",
        "优惠金额": "Discount amount: ",
        "实际支付金额": "Actual payment amount: ",
        "money symbol": "$",
        "活动时间": "Time: ",
        "活动地点": "Address: ",
        "立即预登记": "Register now",
        "已预登记，查看我的门票": "I have registered, check my tickets",
        "success title prefix": "Your payment to the ",
        "success title suffix": " has been successful.",
        "请输入订单号": "Please enter order number",
        "booth": "Booth No.: ",
        "订单状态": "Order Status",
        "status 所有": "All",
        "status 退款": "Refunded",
        "status 取消": "Cancelled",
        "status 待付款": "Unpaid",
        "status 已付款": "Paid",
        "status 待确认": "To be confirmed",
        "order 订单号": "Order",
        "order 峰会名称": "Summit Name",
        "order 数量": "Quantity",
        "order 费用": "Fee",
        "order 支付状态": "Payment Status",
        "order 支付方式": "Payment Method",
        "order 下单时间": "Order time",
        "order 操作": "Operation",
        "申请发票": "Apply for invoice",
        "查看发票": "View Invoice",
        "查看门票": "View Tickets",
        "delete order": "Are you sure to cancel this order?",
        "delete order success": "This order has been deleted",
        "门票数量": "Number of tickets: ",
        "未核销": "Unrecognized",
        "已核销": "Verified off",
        "更换邮箱": "Change Email",
        "更换手机": "Change Mobile",
        "change mobile success": "Mobile changed successfully",
        "change email success": "Email changed successfully",
        "exchange code unconfirmed": "You have an unconfirmed redemption code",
        "edemption codes cannot exceed votes": "The number of redemption codes cannot exceed the number of votes",
        "unfilled or unconfirmed redemption codes": "You still have unfilled or unconfirmed redemption codes",
        "success": {
            "tip": "The forum ticket does not include the exhibition entrance ticket. please click the button to complete the compulsory registration.",
            "title prefix": "Your payment to the ",
            "title suffix": " has been successful.",
        },
        "writeoff": {
            "tip": "The forum ticket does not include the exhibition entrance ticket. please click the button to complete the compulsory registration.",
            "view ticket": "Check my confirmation code",
            "register": "Registration now"
        },
        "tips": "Important Note: ",
    }
}
 
export default en