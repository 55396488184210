//cn.js
const cn = {
    "baseurl": "",
    "lang": "cn",
    "head_title": "专业观众会员中心——2025 SIAL西雅国际食品和饮料展览会",
    "head_description": "SIAL西雅展(上海)将于2025年5月19日-21日在上海新国际博览中心举办，展览面积将达200,000㎡，预计吸引全球75个国家和地区的5,000+展商和全球110个国家和地区的180,000+专业人士。同期还将举办SIAL世界食品产业峰会和赛事及活动。SIAL 西雅展(深圳)将于2025年9月1日-3日，在深圳会展中心(福田)举办，展览面积将达到60,000平方米，预计吸引全球50个国家和地区的1,500+展商和67,000位专业人士，同期还将举办SIAL世界食品产业峰会和赛事及活动。",
    "head_keywords": "食品展2025,专业买家，SIAL西雅展，会员中心，买家入口，食饮选品",
    "meta": {
        "概览": "概览",
        "首页": "首页",
        "会员信息": "会员信息",
        "个人信息": "个人信息",
        "修改密码": "修改密码",
        "参观信息": "参观信息",
        "参观确认函": "参观确认函",
        "问卷信息": "问卷信息",
        "信息订阅": "信息订阅",
        "商贸配对": "商贸配对",
        "配对信息": "配对信息",
        "在线预约": "在线预约",
        "预约管理": "预约管理",
        "我的收藏": "我的收藏",
        "收藏展商": "收藏展商",
        "收藏展品": "收藏展品",
        "邀请管理": "邀请管理",
        "推荐给好友": "推荐给好友",
        "协助他人登记": "协助他人登记",
        "团体导入": "团体导入",
        "峰会直播": "峰会直播",
        "我的订单": "我的订单",
        "我的门票": "我的门票",
        "我的赠票": "我的赠票",
        "我的订阅": "我的订阅",
        "线下峰会": "线下峰会",
        "峰会购票": "峰会购票",
        "白皮书下载": "白皮书下载",
        "我的下载": "我的下载",
        "沙龙报名": "沙龙报名",
        "发票申请": "发票申请",
        "展馆交通": "展馆交通",
        "联系主办方": "联系主办方",
        "发起的约见": "发起的约见",
        "收到的约见": "收到的约见",
        "我的日程": "我的日程",
    },
    "￥": "￥",
    "home": "概览",
    "confirm": "确 定",
    "cancel": "取 消",
    "please select payment": "请先选择支付方式",
    "select payment": "选择支付方式",
    "paid success": "付费成功",
    "paid successfully": "已支付成功",
    "none payment": "未支付",
    "paid": "已支付",
    "Wechat Pay": "微信支付",
    "Alipay Pay": "支付宝支付",
    "Offline payment": "线下支付",
    "coupon": "优惠券",
    "input coupon": "请输入您的观看优惠码",
    "submit payment": "立即支付",
    "open Alipay payment interface": "请在打开的支付宝支付界面进行支付",
    "Bank Transfer Information": "线下支付信息",
    "paying": "支付中...",
    "Pay with a swipe on wechat": "微信扫一扫支付",
    "Tickets purchased successfully, please pay offline": "购票成功，请于线下支付",
    "You have paid successfully, to My Ticket": "您已支付成功，请于“我的门票”查看入场码！",
    "Warm reminder": "温馨提示",
    "payment within 2 hours": "请于2小时内完成支付，订单到期将自动取消。",
    "Order information": "订单信息",
    "Order number": "订单编号",
    "truename": "姓名",
    "company": "公司",
    "mobile": "手机",
    "email": "邮箱",
    "number": "数量",
    "unit price": "单价",
    "total order price": "订单总价",
    "Discount amount": "优惠金额",
    "Amount actually paid": "实际支付金额",
    "View order": "查看订单",
    "live time": "直播时间",
    "Please log in": "请先登录",
    "Log back in": "重新登录",
    "The request timed out. Please refresh and try again": "请求超时，请刷新后重试",
    'date': {
        'D': '天',
        'h': '时',
        'm': '分',
        's': '秒',
    },
    "livepay tips": "购买后视频观看有效期为21天",
    "please enter": "请输入",
    "please select": "请选择",
    "save success": "保存成功",
    "email code": "邮箱验证码",
    "sms code": "短信验证码",
    "send sms code": "获取验证码",
    "send email code": "获取验证码",
    "getting code": "获取验证码中",
    "a valid": "有效的",
    "to reacquire": "后重新获取",
    "go another language version to pay": "前往英文版本支付",
    "btn": {
        "save": "保存",
        "confirm": "确定",
        "submit": "提交",
        "delete": "删除",
        "pay now": "立即支付",
        "cancel": "取消",
    },
    "newelive": {
        "tdk": {
            "title": "2025食品展SIAL世界食品产业峰会官方购票入口",
            "description": "SIAL世界食品产业峰会旨在传递食饮行业前沿资讯，剖析行业发展新趋势。峰会围绕新零售、供应链、乳业、肉类、饮品、食品科技、进口食品、食品投融资、中餐等食饮行业热门话题及品类，邀请全球顶尖专家学者参与并发表主题演讲，参会人士也有机会就行业热门议题进行深入探讨，助力食饮企业获得更多高价值合作商机和行业信息。",
            "keywords": "2025食品展，SIAL世界食品产业峰会，峰会直播，峰会大咖说，线下峰会"
        },
        "index": {
            "title": "关于SIAL 世界食品产业峰会",
            "introduction": "SIAL世界食品产业峰会旨在传递食饮行业前沿资讯，剖析行业发展态势，展望未来发展新机遇，围绕新零售、供应链、肉类、饮品、食品科技、进口食品、健康食品、餐饮等时下行业热门品类及话题设置主题论坛，邀请国内外行业专家学者参与并发表主题演讲，参会人士也有机会就行业热门议题进行深入探讨，助力食饮企业获得更多高价值合作商机和行业信息。",
        },
        "back list": "返回列表",
        "back page": "返回上一页",
        "首页": "首页",
        "登录": "登录",
        "食品产业峰会": "食品产业峰会",
        "选择票种": "选择票种",
        "我的门票": "我的门票",
        "线下峰会": "线下峰会",
        "我的订单": "我的订单",
        "发票信息": "发票信息",
        "个人信息": "个人信息",
        "门票核销": "门票核销",
        "支付": "支付",
        "购票成功": "购票成功",
        "退出登录": "退出登录",
        "登录/注册": "登录/注册",
        "我的": "我的",
        "please login": "请先登录",
        "实名制预登记": "实名制预登记",
        "我要购票": "我要购票",
        "峰会大咖说": "峰会大咖说",
        "往届回顾": "往届回顾",
        "直播回看": "直播回看",
        "个人中心": "个人中心",
        "home page": "SIAL 西雅展官网",
        "上海食品产业峰会": "2025 SIAL 世界食品产业峰会",
        "查看详情": "查看详情",
        "详情": "详情",
        "暂无数据": "暂无数据",
        "搜索": "搜索",
        "请输入关键字": "请输入关键字",
        "footer copy": "北京爱博西雅展览有限公司 版权所有",
        "step 订单确认": "订单确认",
        "step 支付方式": "支付方式",
        "step 购票完成": "购票完成",
        "票种": "票种",
        "数量": "数量",
        "早鸟价": "早鸟价：",
        "正价": "正价：",
        "select 票种": "请选择票种",
        "订单信息": "订单信息",
        "会议名称": "会议名称",
        "票价": "票价",
        "兑换码": "兑换码：",
        "请输入兑换码": "请输入兑换码",
        "兑换码重复": "兑换码重复",
        "确认使用": "确认使用",
        "新增兑换码": "新增兑换码",
        "订单金额合计": "订单金额合计：",
        "优惠金额": "优惠金额：",
        "实际支付金额": "实际支付金额：",
        "money symbol": "￥",
        "活动时间": "活动时间：",
        "活动地点": "活动地点：",
        "立即预登记": "立即登记",
        "已预登记，查看我的门票": "已完成登记，查看我的门票",
        "请输入订单号": "请输入订单号",
        "booth": "展位号：",
        "订单状态": "订单状态",
        "status 所有": "所有",
        "status 退款": "退款",
        "status 取消": "取消",
        "status 待付款": "待付款",
        "status 已付款": "已付款",
        "status 待确认": "待确认",
        "order 订单号": "订单号",
        "order 峰会名称": "峰会名称",
        "order 数量": "数量",
        "order 费用": "费用",
        "order 支付状态": "支付状态",
        "order 支付方式": "支付方式",
        "order 下单时间": "下单时间",
        "order 操作": "操作",
        "申请发票": "申请发票",
        "查看发票": "查看发票",
        "查看门票": "查看门票",
        "delete order": "确定取消该订单吗？",
        "delete order success": "删除订单成功",
        "门票数量": "门票数量：",
        "未核销": "未核销",
        "已核销": "已核销",
        "更换邮箱": "更换邮箱",
        "更换手机": "更换手机",
        "change mobile success": "更换手机成功",
        "change email success": "更换邮箱成功",
        "exchange code unconfirmed": "您有兑换码未确认",
        "edemption codes cannot exceed votes": "兑换码数量不能超过票数",
        "unfilled or unconfirmed redemption codes": "您还有未填写或未确认的兑换码",
        "success": {
            "tip": "论坛与展会同期举办，论坛门票不包含展会门票，请点击按钮完成登记。",
            "title prefix": "您已经成功购买",
            "title suffix": "门票",
        },
        "writeoff": {
            "tip": "论坛与展会同期举办，论坛门票不包含展会门票，请尽快完成展会登记。",
            "view ticket": "已登记，查看入场码",
            "register": "立刻登记"
        },
        "tips": "重要提示：",
    }
}
 
export default cn