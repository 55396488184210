import request from "@/utils/request_neweventlive";
import Cookies from "js-cookie";

const state = {
  eventlive_show_login: false,
  eventlive_show_profile: false,
  isLogin: Cookies.get("is_login_neweventlive"),
  truename: Cookies.get("neweventlive_truename"),
};
const mutations = {
  SET_EVENTLIVE_SHOW_LOGIN: (state, data) => {
    state.eventlive_show_login = data;
  },
  SET_EVENTLIVE_SHOW_PROFILE: (state, data) => {
    state.eventlive_show_profile = data;
  },
  SET_ISLOGIN: (state, data) => {
    state.isLogin = data;
  },
  SET_TRUENAME: (state, data) => {
    state.truename = data;
  },
};
const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { app_lang } = userInfo;
    return new Promise((resolve, reject) => {
      request({
        url: "/login",
        method: "post",
        data: userInfo,
      })
        .then((response) => {
          const { data } = response;
            // 存放令牌状态
            commit("SET_ISLOGIN", true);
            Cookies.set("is_login_neweventlive", true)
            commit("SET_TRUENAME", data.truename);
            Cookies.set("neweventlive_truename",data.truename);
            
            resolve(response);
        })
        .catch((error) => {
          console.log("store-登录-catch",error);
          reject(error);
        });
    });
  },
  // 退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/loginOut",
        method: "post",
        data: "",
      })
        .then(() => {
          // 清除令牌状态
          commit("SET_ISLOGIN", false);
          commit("SET_TRUENAME", "");

          Cookies.remove("is_login_neweventlive");
          Cookies.remove("neweventlive_truename");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
