import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import showMsg from "@/utils/message";
import store from "@/store/index";

const instance = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: process.env.VUE_APP_NEW_EVENTLIVE_BASE_URL,
  // 设置请求超时时间
  timeout: 5*60*1000,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 请求错误的统一处理
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if(!res.code) {
      return res;
    }
    if(res.code === 200 || res.code === 1) {
      return res;
    }else {
      if (res.code === 10001 || res.code === 10002) {
        // 重新登录
        store.commit("neweventlive/SET_EVENTLIVE_SHOW_LOGIN",true);
      }
      return Promise.reject(res);
      
    }
  },
  (error) => {
    console.log("request-err" , error); // for debug
    showMsg.error(error, {duration: 5 * 1000,})
    return Promise.reject(error);
  }
);

export default instance;